@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700,900&display=swap');

/* bootstrap */
$theme-colors: (
  "primary": #081c35,
  "danger": #ff4136
);
@import "~bootstrap/scss/bootstrap";

/* slick slider */
$slick-loader-path: "../../node_modules/slick-carousel/slick/" !default;
$slick-font-path: "../../node_modules/slick-carousel/slick/fonts/" !default;
@import "~slick-carousel/slick/slick";
@import "~slick-carousel/slick/slick-theme";
.slick-next:before, .slick-prev:before { display: none !important; }
.slick-next,
.slick-prev { color: #1d4782 !important; font-size: 40px; height: 40px; }
.slick-slide { height: auto; }
@import "photoswipe/dist/photoswipe.css";

/* Animations */
@import '~aos/src/sass/aos';
@import '../../node_modules/photoswipe/dist/photoswipe.css';

/* icons */
$fa-font-path: '../../node_modules/@fortawesome/fontawesome-free/webfonts';
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";

/* Custom Animations */
@keyframes zoom-fade {
  from {
    opacity: 0;
    -webkit-transform: scale(1.4, 1.4);
    transform: scale(1.4, 1.4);
  }

  to {
    opacity: 1;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

.drifter-gallery {
  width: 100%;
  text-align: center;
  img {
    width: 100%;
    height: auto;
  }
  figure {
    display: inline-block;
    margin: 0 5px 5px 0;
    width: 150px;
  }
  figcaption {
    display: none;
  }
}

/* Global */
.clearfix {
  clear: both;
}
.clearfix::before,
.clearfix::after {
    content: " "; /* 1 */
    display: table; /* 2 */
}

.clearfix::after {
    clear: both;
}
/* Fonts */

h1,
h1.display-1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  &.strong {
    font-weight: 900;
  }
  &.thin {
    font-weight: 400; 
  }
}

h1.subpage-head {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 3rem;
}

.subpage-subhead {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}

h1.display-1 {
  color: #fff;
  font-family: 'Open Sans', sans-serif;
  font-weight: 900;
  text-shadow: 0px 0px 5px rgba(0,0,0,0.5);
}

h2.display {
  position: relative;
  &::after {
    content: '';
    display: block;
    background-color: #081c35;
    height: 2px;
    width: 80px;
    position: absolute;
    left: 50%;
    bottom: -1px;
    transform: translate(-50%);
  }
}
.blue-bg h2.display::after {
  background-color: #FFF;
}

p {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}
.large { font-size: 1.5em; }

a {
  color: #000;
}

strong,
b,
.bold {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
}

.thin { 
  font-weight: 400; 
}

/* Colors */
.yellow, .gold {
  color: #D4AF37 !important;
}

.yellow:hover, .yellow:active {
  color: #D4AF37;
}

.teal, .light-blue {
  color: #41c9bc
}

.blue { color: #081c35; }

.blue-bg { background-color: #081c35; }

.blue-bg-75 {
  background-color: rgba(#081c35, 0.75);
}

.reverse {
  color: #fff;
}

.white-bg {
  background-color: #fff;
}

.black-bg {
  background-color: #000;
}

.gray-bg {
  background-color: #EEE;
}

/* Global */
.center {
  width: 100%;
  text-align: center;
  img {
    margin-left: auto;
    margin-right: auto;
  }
}
.center-lg {
  text-align: left;
  @include media-breakpoint-up(lg) {
    text-align: center;
  }
}

.navbar,
.navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
  position: relative;
  z-index: 1000;
}

.teal-border-bottom {
  border-bottom: solid 15px #41c9bc;
}

/* Nav */
.nav-link {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  color: #000 !important;
  text-transform: uppercase;
  font-size: 1.25rem;
}

.dropdown-item {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  color: #000 !important;
  font-size: 1.25rem;
}

.dropdown-menu {
  z-index: 3001;
}

/* Footer */
footer {
  * {
    color: #fff;
  }
  ul {
    list-style: none;
    li { display: inline; padding-left: 10px; }
  }
}

/* Hero */
#hp-hero-cont {
  position: relative;
  overflow: hidden;
}

#hp-hero {
  background-image: url('../static/hero-bg.jpg');
  animation: zoom-fade 2s cubic-bezier(0.26, 0.54, 0.32, 1) 0s forwards;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  transition: 0s linear;
  transition-property: background-position;
  & > div { animation: none; }
}

/* Content */
#gallery { 
  max-width: 600px; margin-left: auto; margin-right: auto; 
}

/* Rates Table */
.align-center td { vertical-align: middle; }

/* FAQs */
h2.faq-question {
  font-size: 1.75rem;
  margin-top: 30px;
  &:hover {
    color: #333;
    cursor: pointer;
  }
}

.faq-answer {
  display: none;
}

//Device Specific
@include media-breakpoint-up(xs) {

}

@include media-breakpoint-up(sm) {

}

@include media-breakpoint-up(md) {

}

@include media-breakpoint-up(lg) {
  .nav-link {
    font-size: 1rem;
    margin-right: 10px; 
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  #hp-hero { min-height: 450px; }
}

@include media-breakpoint-up(xl) {
  .nav-link {
    font-size: 1.25rem;
    margin-right: 15px;
    padding-left: 0.35rem !important;
    padding-right: 0.35rem !important;
  }
}

@include media-breakpoint-down(xl) {

}

@include media-breakpoint-down(lg) {
  .navbar-toggler {
    .fa-times { display: block; }
    .fa-bars { display: none; }
    &.collapsed {
      .fa-times { display: none; }
      .fa-bars { display: block; }
    }
  }

}

@include media-breakpoint-down(md) {
  .nav-link { margin-left: 15px; }
  .large { font-size: 1rem !important; }
  #hp-hero { min-height: 300px; }
}

@include media-breakpoint-down(sm) {
  .register { 
    padding: 10px; 
    font-size: 1rem;
  }
  h1,.h1,h1.subpage-head { font-size: 2rem; }
  h2,.h2 { font-size: 1.5rem; }
  h3,.h3 { font-size: 1.25rem; }
}

@include media-breakpoint-down(xs) {

}